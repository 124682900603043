import qs from 'querystring';

import {
  HIDDEN_VERCEL_PARAMS,
  removeExcludedQueryParams,
} from '~/shared/services/LinkService';

export const buildUrl = (variation, request) => {
  const querystring = qs.stringify({
    ...variation.params,
    ...removeExcludedQueryParams(request.query, HIDDEN_VERCEL_PARAMS),
    redirected: true,
  });
  return `${variation.redirectUrl}?${querystring}`;
};

export const getGenericUrl = (
  variation1,
  variation2,
  variationKey,
  request,
) => {
  const isVariation1 = variationKey === variation1.key;
  const isVariation2 = variationKey === variation2.key;

  if (request.query?.redirected) {
    return null;
  }

  if (isVariation1) {
    return buildUrl(variation1, request);
  }
  if (isVariation2) {
    return buildUrl(variation2, request);
  }
  return null;
};

import { getAll as getCookies } from 'es-cookie';
import { EventType } from '@ninetailed/experience.js-shared';
import NinetailedPrivacyPlugin from '@ninetailed/experience.js-plugin-privacy';
import * as Sentry from '@sentry/nextjs';

import {
  getConsentStatus,
  getActiveCookieCategoriesFromCookies,
  CookieObjectType,
} from '~/shared/services/onetrust';
import isServer from '~/shared/util/is-server';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export type WindowNinetailedWithPrivacyPlugin = Window['ninetailed'] &
  NinetailedPrivacyPlugin['methods'];

export type PrivacyPluginOptions = {
  allowedEvents?: EventType[];
  allowedPageEventProperties?: string[];
  allowedTrackEvents?: string[];
  allowedTrackEventProperties?: string[];
  allowedTraits?: string[];
};

export function getNinentailedPrivacyPluginOptions(
  request: RequestType,
): PrivacyPluginOptions {
  const cookiesObject = isServer ? request?.cookies || {} : getCookies();
  const activeCookieCategories = getActiveCookieCategoriesFromCookies(
    cookiesObject as CookieObjectType,
  );
  const consentStatus = getConsentStatus(activeCookieCategories);
  // https://docs.ninetailed.io/frameworks/privacy-plugin
  const pluginOptions = !consentStatus.functional
    ? {
        allowedEvents: [],
        allowedPageEventProperties: [],
      }
    : {
        allowedEvents: ['page', 'track', 'identify'],
        allowedPageEventProperties: ['*'],
        allowedTrackEvents: ['*'],
        allowedTrackEventProperties: ['*'],
        allowedTraits: ['*'],
      };

  return pluginOptions as PrivacyPluginOptions;
}

export function toggleNinetailedConsentInBrowser() {
  const cookiesObject = getCookies();
  const activeCookieCategories =
    getActiveCookieCategoriesFromCookies(cookiesObject);
  const consentStatus = getConsentStatus(activeCookieCategories);

  if (window.ninetailed) {
    Sentry.addBreadcrumb({
      category: 'debug',
      message: 'check ninetailed privacy plugin',
      data: {
        ...window.ninetailed,
        profile: '(hidden)',
      },
      level: 'info',
    });
    (window.ninetailed as WindowNinetailedWithPrivacyPlugin).consent(
      consentStatus.functional,
    );
  }
}

import {
  ExperienceMapper,
  ExperienceLike,
  Audience,
} from '@ninetailed/experience.js-utils';
import { ExperienceConfiguration } from '@ninetailed/experience.js-shared';
import getConfig from 'next/config';

import {
  NINETAILED_EXPERIENCE_DELIMITER,
  NINETAILED_VARIANT_CONNECTOR,
} from './constants';

import {
  FlattenedNinetailedAudience,
  FlattenedNinetailedExperience,
} from '~/shared/services/contentful';
import {
  NextJsConfig,
  PublicRuntimeConifg,
} from '~/shared/types/nextjs-config';
import * as ENVIRONMENTS from '~/shared/constants/environments';
import { BaseUrl } from '~/shared/services/AppService';
import api from '~/shared/services/api';

export type NtExperiencesMap = Record<string, number>;

export interface AudienceForPreviewPlugin extends Audience {
  name: string;
}

export type NinetailedPreviewData = {
  experiences?: ExperienceConfiguration[];
  audiences?: AudienceForPreviewPlugin[];
};

export function transformContentfulNtExperience(
  ntExperience: FlattenedNinetailedExperience,
): ExperienceLike {
  return {
    id: ntExperience?.id,
    name: ntExperience?.nt_name,
    type: ntExperience?.nt_type,
    config: ntExperience?.nt_config,
    description: ntExperience?.nt_description,
    audience: {
      id: ntExperience?.nt_audience?.id,
    },
    variants: (ntExperience?.nt_variants || []).map((variant) => ({
      ...variant,
      id: variant.id,
    })),
  };
}

export function mapExperiences(
  ntExperiences: FlattenedNinetailedExperience[] = [],
): ExperienceConfiguration[] {
  return ntExperiences
    .map(transformContentfulNtExperience)
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience);
}

export function transformContentfulNtAudience(
  ntAudience: FlattenedNinetailedAudience,
): AudienceForPreviewPlugin {
  return {
    id: ntAudience?.id,
    name: ntAudience?.nt_name || '',
    description: ntAudience?.nt_description || '',
  };
}

export function mapAudiences(
  ntAudiences: FlattenedNinetailedAudience[] = [],
): AudienceForPreviewPlugin[] {
  return ntAudiences.map(transformContentfulNtAudience);
}

export function parseNinetailedExperienceQuery(
  experienceStr: string = '',
): NtExperiencesMap {
  return experienceStr
    .split(NINETAILED_EXPERIENCE_DELIMITER)
    .reduce((result, experienceVariantStr) => {
      const [key, value] = experienceVariantStr.split(
        NINETAILED_VARIANT_CONNECTOR,
      );

      if (key && value) {
        // eslint-disable-next-line no-param-reassign
        result[key] = Number(value);
      }

      return result;
    }, {});
}

export async function fetchNinetailedPreviewData(
  baseUrl: BaseUrl,
  vercelPasswordProtectionCookie?: string,
): Promise<NinetailedPreviewData> {
  const data = (await api
    .getEntry(`${baseUrl.protocol}//${baseUrl.host}/api/nt-preview-data/`, {
      vercelPasswordProtectionCookie,
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('ninetailed preview data request failed', err);
      return { experiences: [], audiences: [] };
    })) as Promise<NinetailedPreviewData>;

  return data;
}

export function isNinetailedPreviewEnabled() {
  const { publicRuntimeConfig = {} } = getConfig() as NextJsConfig;
  const { environment, vercelEnvironment } =
    publicRuntimeConfig as PublicRuntimeConifg;
  const isProduction =
    environment === ENVIRONMENTS.PRODUCTION &&
    vercelEnvironment === ENVIRONMENTS.PRODUCTION;

  return !isProduction;
}

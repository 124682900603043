// Q3 2024 UK, DE, IT, FR New homepage & category page A/B test
// https://sumupteam.atlassian.net/wiki/spaces/GPMT/pages/22988062938/HP+Cat+Page+-+Experiment+Summary

import { buildUrl } from '../utils';

import {
  Q3_HOMEPAGE_VARIATION_KEY,
  Q3_HOMEPAGE_REDIRECTS_MAP,
} from './constants';

import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export const getRedirectUrl = (variationKey: string, request: RequestType) => {
  if (
    request?.query?.redirected ||
    variationKey !== Q3_HOMEPAGE_VARIATION_KEY
  ) {
    return null;
  }

  const redirectUrl = Q3_HOMEPAGE_REDIRECTS_MAP[request?.pathname];

  return redirectUrl ? buildUrl({ redirectUrl }, request) : null;
};

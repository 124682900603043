import find from 'lodash/fp/find';

import * as q3HomepageExperiment from './redirect-experiments/q3-homepage';
import { Q3_HOMEPAGE_EXPERIMENT_KEY } from './redirect-experiments/q3-homepage/constants';

export const REDIRECT_EXPERIMENTS = {
  [Q3_HOMEPAGE_EXPERIMENT_KEY]: q3HomepageExperiment,
};

const REDIRECT_EXPERIMENT_KEYS_BY_PRIO = [Q3_HOMEPAGE_EXPERIMENT_KEY];

export const findByPriority = (
  activeExperiments = [],
  redirectExperimentKeysByPrio = REDIRECT_EXPERIMENT_KEYS_BY_PRIO,
) => {
  let highestPriorityExperiment;
  for (let i = 0; i < redirectExperimentKeysByPrio.length; i += 1) {
    highestPriorityExperiment = find(
      (activeExperiment) =>
        activeExperiment.experimentKey === redirectExperimentKeysByPrio[i],
      activeExperiments,
    );
    if (highestPriorityExperiment) {
      break;
    }
  }
  return highestPriorityExperiment || {};
};

export const getRedirectUrl = (
  optimizelyExperiments,
  request,
  redirectExperiments = REDIRECT_EXPERIMENTS,
  redirectExperimentKeysByPrio = REDIRECT_EXPERIMENT_KEYS_BY_PRIO,
) => {
  const experiment = findByPriority(
    optimizelyExperiments,
    redirectExperimentKeysByPrio,
  );
  const experimentHandler = redirectExperiments[experiment.experimentKey];
  if (experimentHandler) {
    return experimentHandler.getRedirectUrl(experiment.variationKey, request);
  }
  return null;
};

import React, { FC, useEffect, useContext } from 'react';
import {
  NinetailedProvider,
  ESRProvider,
  useNinetailed,
} from '@ninetailed/experience.js-next';
import NinetailedSsrPlugin from '@ninetailed/experience.js-plugin-ssr';
import NinetailedPrivacyPlugin from '@ninetailed/experience.js-plugin-privacy';
import { NinetailedInsightsPlugin } from '@ninetailed/experience.js-plugin-insights';
import { NinetailedGoogleTagmanagerPlugin } from '@ninetailed/experience.js-plugin-google-tagmanager';
import NinetailedPreviewPlugin from '@ninetailed/experience.js-plugin-preview';
import * as Sentry from '@sentry/nextjs';

import {
  NINETAILED_IS_ENABLED,
  NINETAILED_CLIENT_ID,
  NINETAILED_ENVIRONMENT,
  NINETAILED_COOKIE_MAX_AGE_DAYS,
} from '~/shared/services/ninetailed/constants';
import * as OneTrustService from '~/shared/services/onetrust';
import {
  toggleNinetailedConsentInBrowser,
  getNinentailedPrivacyPluginOptions,
} from '~/shared/services/ninetailed/consent';
import { getCookieDomain } from '~/shared/services/cookie-domain';
import {
  NinetailedPreviewData,
  NtExperiencesMap,
  isNinetailedPreviewEnabled,
} from '~/shared/services/ninetailed/shared';
import SiteContext from '~/shared/providers/SiteContext';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export type NinetailedWrapperProps = {
  request: RequestType;
  ntExperiencesVariantsMap?: NtExperiencesMap;
  ninetailedPreviewData?: NinetailedPreviewData;
  children: React.ReactNode;
};

type NinetailedRequestQuery = {
  hideNinetailedPreviewButton?: boolean;
};

const TempNinetailedInstanceDebugger = ({ children }) => {
  const ninetailed = useNinetailed();

  useEffect(() => {
    if (!NINETAILED_IS_ENABLED) {
      return;
    }

    OneTrustService.onConsentChange(() => {
      Sentry.addBreadcrumb({
        category: 'debug',
        message: 'check Ninetailed instance plugins',
        data: {
          ninetailedPluginNames: ninetailed?.plugins?.map(
            (plugin) => plugin.name,
          ),
          ninetailedPluginInstances: ninetailed?.plugins,
        },
        level: 'info',
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export const NinetailedWrapper: FC<NinetailedWrapperProps> = ({
  request,
  ntExperiencesVariantsMap = {},
  ninetailedPreviewData,
  children,
}) => {
  const { environmentId: contentfulEnvironmentId, spaceId: contentfulSpaceId } =
    useContext(SiteContext);

  let ninetailedPlugins: (
    | NinetailedSsrPlugin
    | NinetailedPrivacyPlugin
    | NinetailedInsightsPlugin
    | NinetailedGoogleTagmanagerPlugin
    | NinetailedPreviewPlugin
  )[] = [];

  useEffect(() => {
    if (!NINETAILED_IS_ENABLED) {
      return;
    }

    OneTrustService.onConsentChange(() => {
      Sentry.addBreadcrumb({
        category: 'debug',
        message: 'check initialized Ninetailed plugin list',
        data: {
          ninetailedPluginNames: ninetailedPlugins?.map(
            (plugin) => plugin.name,
          ),
          ninetailedPluginInstances: ninetailedPlugins,
        },
        level: 'info',
      });

      toggleNinetailedConsentInBrowser();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!NINETAILED_IS_ENABLED) {
    return <>{children}</>;
  }

  ninetailedPlugins = [
    new NinetailedSsrPlugin({
      cookie: {
        domain: getCookieDomain(request?.host),
        expires: NINETAILED_COOKIE_MAX_AGE_DAYS,
      },
    }),
    new NinetailedPrivacyPlugin(getNinentailedPrivacyPluginOptions(request)),
    new NinetailedInsightsPlugin(),
    new NinetailedGoogleTagmanagerPlugin(),
  ];

  if (isNinetailedPreviewEnabled() && ninetailedPreviewData) {
    const contentfulEditorUrlBase =
      contentfulEnvironmentId && contentfulSpaceId
        ? `https://app.contentful.com/spaces/${contentfulSpaceId}/environments/${contentfulEnvironmentId}/entries/`
        : undefined;

    ninetailedPlugins.push(
      new NinetailedPreviewPlugin({
        experiences: ninetailedPreviewData?.experiences,
        audiences: ninetailedPreviewData?.audiences,
        onOpenExperienceEditor: (experience) => {
          if (contentfulEditorUrlBase) {
            window.open(`${contentfulEditorUrlBase}${experience.id}`, '_blank');
          }
        },
        onOpenAudienceEditor: (audience) => {
          if (contentfulEnvironmentId && contentfulSpaceId) {
            window.open(`${contentfulEditorUrlBase}${audience.id}`, '_blank');
          }
        },
        ui: {
          opener: {
            hide: !!(request?.query as NinetailedRequestQuery)
              ?.hideNinetailedPreviewButton,
          },
        },
      }),
    );
  }

  return (
    <NinetailedProvider
      clientId={NINETAILED_CLIENT_ID}
      environment={NINETAILED_ENVIRONMENT}
      plugins={ninetailedPlugins}
    >
      <ESRProvider experienceVariantsMap={ntExperiencesVariantsMap}>
        <TempNinetailedInstanceDebugger>
          {children}
        </TempNinetailedInstanceDebugger>
      </ESRProvider>
    </NinetailedProvider>
  );
};
